window.addEventListener("load", (event) => {
    // Add static links
    const linksClass = {
        'geoRisksLink': 'https://www.georisques.gouv.fr'
    }

    for (const [label, url] of Object.entries(linksClass)) {
        let elements = document.getElementsByClassName(label)
        for (let i = 0;i < elements.length;i++) {
            let linkElement = document.createElement("a")
            linkElement.href = url
            linkElement.setAttribute('target', '_blank')
            linkElement.classList.add(label)
            linkElement.textContent = elements[i].textContent
            elements[i].parentNode.replaceChild(linkElement, elements[i])
        }
    }

    // Add dynamic links
    let elements = document.getElementsByClassName('botHiddenLink')
    for (let i = 0;i < elements.length;i++) {
        let url = ''
        if (typeof elements[i].getAttribute('data-first-part') !== 'undefined' && elements[i].getAttribute('data-first-part') !== null)
            url += elements[i].getAttribute('data-first-part')
        if (typeof elements[i].getAttribute('data-mid-part') !== 'undefined' && elements[i].getAttribute('data-mid-part') !== null)
            url += elements[i].getAttribute('data-mid-part')
        if (typeof elements[i].getAttribute('data-last-part') !== 'undefined' && elements[i].getAttribute('data-last-part') !== null)
            url += elements[i].getAttribute('data-last-part')

        elements[i].href = url
    }

})