(function () {
  // gestion des href dans le header
  // To remove after mep new header
  const header = document.querySelector('header[role="banner"]');
  header
    .querySelectorAll("[data-href-query]")
    .forEach((entry: HTMLAnchorElement) => {
      entry.addEventListener("mousedown", () => {
        var query = entry.getAttribute("data-href-query");
        if (query) {
          entry.href = entry.href.replace(/\?.*/g, "") + `?${query}`;
        }
      });
    });
  header.querySelectorAll("[query]").forEach((entry: HTMLAnchorElement) => {
    entry.addEventListener("mousedown", () => {
      var query = entry.getAttribute("query");
      if (query) {
        entry.href = entry.href.replace(/\?.*/g, "") + `?${query}`;
      }
    });
  });
  (window as any).addTrackingQuerySearchToDetail = (href: string) => {
    return href.replace(/\?.*/g, "") + `?m=search_to_detail`;
  };

  (window as any).addTrackingQueryMapToDetail = (href: string) => {
    return href.replace(/\?.*/g, "") + `?m=map_to_detail`;
  };

  // gestion des href dans les pages Hub
  const pageHub = document.getElementById("hub-search");
  if (pageHub) {
    const linksGroups = document.querySelectorAll(
      "#your-project a, .links-section a"
    );
    if (linksGroups) {
      linksGroups.forEach((link: HTMLAnchorElement) => {
        link.addEventListener("mousedown", () => {
          var query = link.getAttribute("href");
          if (query) {
            link.href =
              link.href.replace(/\?.*/g, "") +
              `?m=lp_hub-redirection-search_results`;
          }
        });
      });
    }
  }
})();
